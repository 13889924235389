import React from "react";
import cx from "classnames";

import { CartView } from "src/components/cart/view";

const CartPage = ({ transitionStatus }: { transitionStatus: string }) => {
  return (
    <div className={cx("animate__page mt1 ac", transitionStatus)}>
      <div className="container--m mxa x al">
        <CartView />
      </div>
    </div>
  );
};

export default CartPage;
